<template>
  <div class="last-modified-container">
    <h2 class="update-header">{{welcomeMessage}}</h2>
  </div>
</template>

<script>
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import userDataAccess from "../../../dataAccess/userDataAccess";

export default {
  name: "lastmodified",
  data() {
    return {
      proficienciesList: [],
      skillsList: [],
      myUser: {},
      proficiencyDAO: new proficiencyDataAccess(),
      userDAO: new userDataAccess()
    };
  },
  methods: {
    async init() {
      this.myUser = await this.userDAO.getAccount();
      this.proficienciesList = await this.proficiencyDAO.getAllProficiencies(this.myUser.email);
    },
    lastModifiedProficiency() {
      return this.myProficiencies.sort(function(x, y) {
        return (
          new Date(y.modifiedDate).getTime() -
          new Date(x.modifiedDate).getTime()
        );
      })[0];
    },
  },
  computed: {
    myProficiencies() {
      return this.proficienciesList.filter(proficiency => {
        return proficiency.email === this.myUser.email;
      });
    },
    
    lastModifiedDate() {
      if (this.myProficiencies.length === 0) {
        return "Never";
      }

      return new Date(
        this.lastModifiedProficiency().modifiedDate
      ).toLocaleDateString();
    },
    modifiedDaysAgoMessage() {
      if (this.myProficiencies.length === 0) {
        return "";
      }

      let lastModifiedDaysAgo = Math.floor((new Date() - new Date(this.lastModifiedProficiency().modifiedDate)) / 86400000);

      if (lastModifiedDaysAgo === 0) {
        return "Today";
      }
      if (lastModifiedDaysAgo === 1) {
        return "Yesterday";
      }
      return lastModifiedDaysAgo + " days ago";
    },
    welcomeMessage(){
      if(!this.modifiedDaysAgoMessage){
        return "Welcome to Omnitech Connect";
      }
      else{
        return "Last Skill Update: " + this.modifiedDaysAgoMessage;
      }
    }
  }
};
</script>

<style>
.last-modified-container{
    margin: 30px;
}
.update-header {
  font-weight: 200;
  color: #666;
}
</style>
