<template>
  <div class="dashboard">
    <div class="title-bar" color="primary">
      <h1>My Dashboard</h1>
    </div>
    <v-alert :value="isErrored" type="error">
      Error contacting the server. Please try again later.
    </v-alert>
    <v-card class="dashboard-container" v-if="!isErrored">
      <v-progress-circular
        v-if="isLoading"
        class="loading-indicator"
        indeterminate
        :color="colors.PRIMARY"
        :size="100"
      ></v-progress-circular>
      <div class="inner-dashboard-container" :class="{'hidden' : isLoading}">
        <LastModified ref="lastModified" />
        <v-divider />
        <div class="dashboard-section">
          <CategoryStrengths
            ref="strongCategories"
            :title="'Strongest Categories'"
            :type="'strong'"
          />
          <CategoryStrengths ref="weakCategories" :title="'Weakest Categories'" :type="'weak'" />
        </div>
        <v-divider />
        <div class="dashboard-section">
          <StaleProficiencies class="subsection" ref="staleProficiencies"/>
          <RecentlyAddedSkills class="subsection" ref="recentlyAddedSkills" />
          <PopularSkills class="subsection" ref="popularSkills" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import colors from "@/colors";
import LastModified from "./components/LastModified";
import StaleProficiencies from "./components/StaleProficiencies";
import RecentlyAddedSkills from "./components/RecentlyAddedSkills";
import CategoryStrengths from "./components/CategoryStrengths";
import proficiencyDataAccess from "../../dataAccess/proficiencyDataAccess";
import skillDataAccess from "../../dataAccess/skillDataAccess";
import PopularSkills from "./components/PopularSkills";
import userDataAccess from "../../dataAccess/userDataAccess";

export default {
  name: "dashboard",
  components: {
    LastModified,
    StaleProficiencies,
    RecentlyAddedSkills,
    CategoryStrengths,
    PopularSkills
  },
  data() {
    return {
      colors,
      isLoading: true,
      proficiencyDAO: new proficiencyDataAccess(),
      skillDAO: new skillDataAccess(),
      userDAO: new userDataAccess(),
      proficienciesList: [],
      skillsList: [],
      myProficienciesList: [],
      isErrored: false
    };
  },
  methods: {
    async initData() {
      let currentUser = await this.userDAO.getAccount();
      let proficienciesListPromise = this.proficiencyDAO.getAllProficiencies(
        currentUser.email
      );
      let skillsListPromise = this.skillDAO.getAllSkills();

      var results = await Promise.all([
        proficienciesListPromise,
        skillsListPromise
      ]);

      this.proficienciesList = results[0];
      this.skillsList = results[1];
      this.myProficienciesList = await this.proficiencyDAO.getProficiencies();
    }
  },
  mounted: async function() {
    try{
      await this.initData();
      await this.$refs.lastModified.init();
      await this.$refs.staleProficiencies.init();
      await this.$refs.recentlyAddedSkills.init();
      await this.$refs.strongCategories.init();
      await this.$refs.weakCategories.init();
      await this.$refs.popularSkills.init();
      this.isLoading = false;
    }
    catch{
      this.isErrored = true;
    }
  }
};
</script>

<style scoped>
.dashboard {
  width: 100%;
  text-align: center;
}

.dashboard-container {
  max-width: 80%;
  margin: 30px;
}

.inner-dashboard-container {
  padding-top: 1px;
  padding-bottom: 1px;
}

.dashboard-section {
  display: flex;
  align-items: top;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px;
}

.dashboard-section .subsection {
  margin: 30px 15px;
  margin-right: 0px;
  min-width: 300px;
  max-height: 400px;
}

.dashboard-section .large-tile {
  margin: 30px 15px;
  margin-right: 0px;
  min-width: 400px;
  min-height: 450px;
}

.dashboard-section .small-tile:last-child {
  margin-right: 15px;
}

.loading-indicator {
  margin-top: 100px;
  margin-bottom: 100px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 800px) {
  .dashboard-container {
    max-width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .dashboard-section {
    flex-direction: column;
    align-content: center;
  }

  .dashboard-section .small-tile:last-child {
    margin-bottom: 30px;
  }

  .dashboard-section .small-tile:last-child {
    margin-right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .dashboard-section div {
    margin-left: 0;
  }

  .dashboard-section .large-tile {
    min-width: 300px;
    min-height: 350px;
    margin-left: 0;
  }
}
</style>
