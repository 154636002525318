<template>
    <v-card class="recently-added-skills small-tile">
        <h2 class="header">Recently Added Skills</h2>
        <v-divider />
        <div v-for="skill in recentlyAddedList" :key="skill.id" class="recently-added">
            <span class="clickable" :style="{'color': colors.PRIMARY}" @click="goToSkill(skill.name)">{{ skill.name }}</span>
            <span v-if="new Date(skill.createdDate).toLocaleDateString() == new Date().toLocaleDateString()">Today</span>
            <span v-else-if="Math.floor((new Date() - new Date(skill.createdDate)) / 86400000) === 1">Yesterday</span>
            <span v-else>{{ Math.floor((new Date() - new Date(skill.createdDate)) / 86400000) }} days ago</span>
        </div>
    </v-card>
</template>

<script>
import colors from "@/colors";
import skillDataAccess from '../../../dataAccess/skillDataAccess';

export default {
    name: "recentlyAddedSkills",
    data() {
        return {
            colors,
            skillDAO: new skillDataAccess(),
            recentlyAddedList: [],
        };
    },
    methods: {
        async init() {
            try {
                var allSkills = await this.skillDAO.getAllSkills();
                this.recentlyAddedList = Object.values(allSkills.sort((skill1, skill2) => {
                    return (
                        new Date(skill2.createdDate).getTime() -
                        new Date(skill1.createdDate).getTime()
                    );
                }));
                this.recentlyAddedList = this.recentlyAddedList.splice(0, 5);
            } catch (err) {
                console.log(err);
                return;
            }
        },
        goToSkill(skill){
            this.$router.push("/skills?skill=" + skill);
        }
    }
}
</script>

<style>
.header {
  font-weight: 200;
  color: #666;
  padding: 5px;
}

.recently-added {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding-right: 35px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.clickable {
  max-width: 150px;
  cursor:pointer;
}

@media screen and (max-width: 500px){
    .recently-added {
        padding-right: 20px;
        padding-left: 20px;
    }
}
</style>