<template>
  <v-card class="small-tile stale-proficiencies" v-if="staleProficiencies().length > 0">
    <h2 class="header">Your Stale Skills</h2>
    <v-divider />
    <div v-for="proficiency in staleProficiencies()" :key="proficiency.id" class="stale-proficiency">
      <span
        class="clickable"
        :style="{'color': colors.PRIMARY}"
        @click="goToProficiency(proficiency.skillName)"
      >{{proficiency.skillName}}</span>
      <span>{{proficiency.daysOld}} Days Old</span>
    </div>
    <v-btn :color="colors.PRIMARY" class="white--text go-to-review" @click="goToReview()">
      Go To Review
    </v-btn>
  </v-card>
</template>

<script>
import colors from "@/colors";
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import userDataAccess from "../../../dataAccess/userDataAccess";

export default {
  name: "StaleProficiencies",
  data() {
    return {
      colors,
      proficiencyDAO: new proficiencyDataAccess(),
      userDAO: new userDataAccess(),
      proficiencies: [],
      myUser: {}
    };
  },
  methods: {
    async init() {
      this.myUser = await this.userDAO.getAccount();
      this.proficiencies = await this.proficiencyDAO.getAllProficiencies(
        this.myUser.email
      );
    },
    goToProficiency(prof) {
      this.$router.push("/skills?proficiency=" + prof);
    },
    goToReview(){
      this.$router.push("/skillreview");
    },
    staleProficiencies() {
      let staleProficiencies = this.myProficiencies
        .sort((prof1, prof2) => {
          return (
            new Date(prof1.reviewedDate).getTime() -
            new Date(prof2.reviewedDate).getTime()
          );
        })
        .filter(prof => {
          return (
            new Date().getTime() - new Date(prof.reviewedDate).getTime() >
            7776000000
          );
        });

      staleProficiencies.forEach(prof => {
        prof.daysOld = Math.floor(
          (new Date() - new Date(prof.reviewedDate)) / 86400000
        );
      });

      return staleProficiencies.slice(0, 5);
    }
  },
  computed: {
    myProficiencies() {
      return this.proficiencies.filter(proficiency => {
        return proficiency.email === this.myUser.email;
      });
    },    
  }
};
</script>

<style>
.header {
  font-weight: 200;
  color: #666;
  padding: 5px;
}

.stale-proficiency {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 35px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 600px;
}

.clickable {
  max-width: 150px;
  cursor: pointer;
}

.go-to-review{
  margin: 10px 0;
  margin-bottom: 15px;
}

@media screen and (max-width: 500px) {
  .stale-proficiency {
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>
