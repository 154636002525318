<template>
  <v-card class="popular-skills">
    <h2 class="header">Popular Skills</h2>
    <v-divider />
    <div v-for="skill in popularSkillsList" :key="skill.id" class="popular">
      <span
        class="clickable"
        :style="{'color': colors.PRIMARY}"
        @click="goToSkill(skill[0].skillName)"
      >{{ skill[0].skillName }}</span>
      <span>{{ skill.length }} Adds</span>
    </div>
  </v-card>
</template>

<script>
import colors from "@/colors";
import _ from 'lodash';
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";

export default {
  name: "popularSkills",
  data() {
    return {
      colors,
      proficiencyDAO: new proficiencyDataAccess(),
      popularSkillsList: []
    };
  },
  methods: {
    async init() {
      try {
        var profList = Object.values(
          await this.proficiencyDAO.getAllProficiencies()
        );
        var myProfs = await this.proficiencyDAO.getProficiencies();

        myProfs.forEach(element => {
          _.remove(profList, function(prof) {
            return prof.skillName == element.skillName;
          });
        });

        var sortedList = profList.sort((prof1, prof2) => {
          return (
            new Date(prof2.createdDate).getTime() -
            new Date(prof1.createdDate).getTime()
          );
        });

        var top50 = sortedList.splice(0, 50);

        var groupedList = Object.values(
          _.groupBy(top50, prof => {
            return prof.skillName;
          })
        );

        sortedList = groupedList.sort((skill1, skill2) => {
          return skill2.length - skill1.length;
        });

        this.popularSkillsList = sortedList.splice(0, 5);
      } catch (err) {
        console.log(err);
        return;
      }
    },
    goToSkill(skill) {
      this.$router.push("/skills?skill=" + skill);
    }
  }
};
</script>

<style>
.popular {
  text-align: left;
  width: 100%;
}

.popular {
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 35px;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.clickable {
  max-width: 150px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .popular {
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>