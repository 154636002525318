<template>
  <v-card class="category-strengths large-tile" :class="{'hidden' : isHidden}">
    <h2 class="header">{{title}}</h2>
    <v-divider />
    <canvas
      :id="'category-chart-' + type"
      width="300"
      height="300"
    ></canvas>
  </v-card>
</template>

<script>
import colors from "@/colors";
import Chart from "chart.js";
import _ from "lodash";
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import userDataAccess from "../../../dataAccess/userDataAccess";

export default {
  name: "CategoryStrengths",
  data() {
    return {
      colors,
      proficiencyDAO: new proficiencyDataAccess(),
      userDAO: new userDataAccess(),
      proficiencies: [],
      user: {}
    };
  },
  props: ["title", "type"],
  methods: {
    async init() {
      this.user = await this.userDAO.getAccount();
      this.proficiencies = await this.proficiencyDAO.getProficiencies(
        this.user.email
      );

      let chartCtx = document.getElementById("category-chart-" + this.type);
      let labels = [];
      let data = [];
      let pointColors = [`rgb(${colors.PRIMARY_RGB})`];

      let filteredProficiencies = [];

      if (this.type === "strong") {
        filteredProficiencies = this.groupedProficiencies.slice(0, 5);
      } else {
        var takeCount =
          Object.keys(this.groupedProficiencies).length > 10
            ? 5
            : Object.keys(this.groupedProficiencies).length - 5;

        filteredProficiencies = this.groupedProficiencies.slice(
          this.groupedProficiencies.length - takeCount,
          this.groupedProficiencies.length
        );
      }

      _.each(filteredProficiencies, grouping => {
        labels.push(grouping.category);
        data.push(grouping.score);
        pointColors.push(`rgb(${colors.PRIMARY_RGB})`);
      });

      var radarChart = new Chart(chartCtx, {
        type: "radar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Skill Level",
              borderColor: [`rgb(${colors.PRIMARY_RGB})`],
              backgroundColor: [`rgb(${colors.PRIMARY_RGB}, .2)`],
              pointBackgroundColor: pointColors,
              borderWidth: 2,
              data: data
            }
          ]
        },
        options: {
          responsive: true,
          scale: {
            ticks: {
              beginAtZero: true
            }
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10
            }
          },
          legend: {
            position: "bottom"
          }
        }
      });
      console.log(radarChart); // TODO: to prevent an "Assigned a value but never used" error... I'm not sure if this is (somehow) needed, but to fix the build, I'm logging this to the console
    },
    getSkillLevelSum(groupedProficiencies) {
      let sum = 0;
      groupedProficiencies.forEach(prof => {
        sum += prof.level;
      });
      return sum;
    }
  },
  computed: {
    groupedProficiencies() {
      if (this.proficiencies.length === 0) {
        return [];
      }
      let groupableList = this.proficiencies.map(prof => prof);

      let result = _(groupableList)
        .groupBy("category")
        .map((category, id) => ({
          category: id,
          score: _.sumBy(category, "level")
        }))
        .value()
        .sort((cat1, cat2) => {
          return cat2.score - cat1.score;
        });

      return result;
    },
    isHidden() {
      if (
        this.groupedProficiencies == null ||
        (Object.keys(this.groupedProficiencies).length <= 7 &&
          this.type === "weak")
      ) {
        return true;
      }

      if (Object.keys(this.groupedProficiencies).length < 3) {
        return true;
      }

      return false;
    },
    windowWidth() {
      return window.innerWidth;
    }
  }
};
</script>
<style>
.category-strengths {
  width: 300px;
  height: 300px;
}

.header {
  font-weight: 200;
  color: #666;
  padding: 5px;
}

.hidden {
  display: none;
}

@media screen and (max-width: 500px) {
  .category-strengths {
    width: 300px;
    height: 300px;
  }
}
</style>
